export const portraitPaths = {
  narrator: {
    // Dr. Zimbardo portrait
    face: "M50 20 C60 20, 70 30, 70 40 C70 50, 65 55, 65 60 C65 65, 60 70, 50 70 C40 70, 35 65, 35 60 C35 55, 30 50, 30 40 C30 30, 40 20, 50 20",
    features: "M40 45 C42 45, 44 46, 45 48 M55 45 C53 45, 51 46, 50 48 M45 55 C47 58, 53 58, 55 55",
    hair: "M30 40 C30 30, 40 20, 50 20 C60 20, 70 30, 70 40 C70 35, 65 25, 50 25 C35 25, 30 35, 30 40"
  },
  guard: {
    // Guard with cap and stern expression
    face: "M50 25 C60 25, 70 35, 70 45 C70 55, 65 60, 65 65 C65 70, 60 75, 50 75 C40 75, 35 70, 35 65 C35 60, 30 55, 30 45 C30 35, 40 25, 50 25",
    features: "M40 50 L45 50 M55 50 L60 50 M45 60 L55 60",
    hat: "M25 40 L75 40 L70 35 L30 35 L25 40 Z"
  },
  prisoner: {
    // Prisoner with shaved head and weary expression
    face: "M50 30 C60 30, 65 40, 65 50 C65 60, 60 65, 50 65 C40 65, 35 60, 35 50 C35 40, 40 30, 50 30",
    features: "M43 48 C44 46, 46 46, 47 48 M53 48 C54 46, 56 46, 57 48 M45 55 C47 53, 53 53, 55 55",
    head: "M35 35 C35 25, 65 25, 65 35 C65 40, 35 40, 35 35"
  },
  psychologist: {
    // Professional with glasses
    face: "M50 25 C60 25, 70 35, 70 45 C70 55, 65 60, 65 65 C65 70, 60 75, 50 75 C40 75, 35 70, 35 65 C35 60, 30 55, 30 45 C30 35, 40 25, 50 25",
    features: "M40 50 L45 50 M55 50 L60 50 M45 60 C47 62, 53 62, 55 60",
    glasses: "M38 48 C40 48, 47 48, 49 48 M51 48 C53 48, 60 48, 62 48 M49 48 L51 48"
  },
  observer: {
    // Observer with notepad
    face: "M50 25 C60 25, 70 35, 70 45 C70 55, 65 60, 65 65 C65 70, 60 75, 50 75 C40 75, 35 70, 35 65 C35 60, 30 55, 30 45 C30 35, 40 25, 50 25",
    features: "M43 50 C44 48, 46 48, 47 50 M53 50 C54 48, 56 48, 57 50 M45 58 C47 60, 53 60, 55 58",
    notepad: "M65 45 L75 45 L75 65 L65 65 Z M67 50 L73 50 M67 55 L73 55 M67 60 L73 60"
  }
} 